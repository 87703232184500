import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[(_vm.processing)?[_c(VRow,_vm._l((4),function(n){return _c(VCol,{key:n,attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VSkeletonLoader,{attrs:{"height":"194","type":"image"}})],1)],1)}),1)]:[_vm._l((_vm.paginatedReleaseNotes),function(release,index){return _c(VCard,{key:index,staticClass:"mb-4"},[_c(VCardTitle,{staticClass:"text-h4 grey--text text--darken-2"},[_vm._v(" Release Notes "),(release.tag_name === _vm.latestRelease.tag_name)?_c(VChip,{staticClass:"ml-2 font-weight-medium",attrs:{"color":"green","label":"","outlined":""}},[_vm._v(" Última Versão ")]):_vm._e()],1),_c(VCardText,{domProps:{"innerHTML":_vm._s(release.bodyHtml)}})],1)}),_c(VPagination,{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }