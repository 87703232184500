<template>
  <v-card flat>
    <template v-if="processing">
      <v-row>
        <v-col cols="12" v-for="n in 4" :key="n">
          <v-card flat>
            <v-skeleton-loader
                height="194"
                type="image"
            />
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card v-for="(release, index) in paginatedReleaseNotes" class="mb-4" :key="index">
        <v-card-title class="text-h4 grey--text text--darken-2">
          Release Notes
          <v-chip
              v-if="release.tag_name === latestRelease.tag_name"
              class="ml-2 font-weight-medium"
              color="green"
              label
              outlined
          >
            Última Versão
          </v-chip>
        </v-card-title>
        <v-card-text v-html="release.bodyHtml" />
      </v-card>
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </template>
  </v-card>
</template>

<script>
import apiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: 'PagesReleaseNotes',

  data: () => ({
    processing: true,
    page: 1,
    itemsPerPage: 10,
    latestRelease: {},
    releaseNotes: [],
  }),

  computed: {
    paginatedReleaseNotes () {
      const items =  this.releaseNotes.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)

      return items.map(item => {
        item.bodyHtml = this.$stringFormat.markdownToHTML(
            item.body
        )

        item.bodyHtml = item.bodyHtml.replace("What\'s Changed", "O que mudou:")
        item.bodyHtml = `<h2>Publicação: ` + this.$date.dateFilter(item.published_at) + `</h2> ${item.bodyHtml}`
        item.bodyHtml = `<h2>Versão: ${item.tag_name.replace('v', '')}</h2> ${item.bodyHtml}`
        item.bodyHtml = item.bodyHtml.replace("<h2", '<span class="title"')
        item.bodyHtml = item.bodyHtml.replace("<h2", '<span class="title"')
        item.bodyHtml = item.bodyHtml.replace("<h2", '<span class="title"')
        item.bodyHtml = item.bodyHtml.replace("</h2>", "</span><br />")
        item.bodyHtml = item.bodyHtml.replace("</h2>", "</span><br />")
        item.bodyHtml = item.bodyHtml.replace("</h2>", "</span><br />")

        return item;
      });
    },

    pageCount () {
      return Math.ceil(this.releaseNotes.length / this.itemsPerPage)
    }
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Home' },
      { title: 'Release Notes', route: '/release-notes' },
    ])

    this.init()
  },

  methods: {
    async init () {
      this.processing = true

      const [releaseNotes, latestRelease] = await Promise.all([
        (await apiService._get('release-notes')).data,
        (await apiService._get('info')).data,
      ])

      this.releaseNotes = releaseNotes
      this.latestRelease = latestRelease

      this.processing = false
    }
  },
}
</script>